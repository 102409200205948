import React, {useState, useEffect} from "react";
import {useHistory, useLocation} from "react-router";
import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import TimeMachineScopeCMSSearchView from "./TimeMachineScopeCMSSearchView";
import TimeMachineScopeCMSImageManagerView from "./TimeMachineScopeCMSImageManagerView";
import TimeMachineScopeCMSMetadataManagerView from "./TimeMachineScopeCMSMetadataManagerView";

const ONE_DAY_MS = 24 * 60 * 60 * 1000;
const FOUR_HOURS_MS = 1000 * 60 * 60 * 4;
const NOW_MS = new Date().getTime();
const INITIAL_END_MS = NOW_MS + parseInt((1.5 * ONE_DAY_MS).toString());
const INITIAL_START_MS = NOW_MS - FOUR_HOURS_MS;

const LANGUAGES_SUPPORTED = ["en-US", "es-419", "pt-BR"];

export default function TimeMachineScopeCMSMasterDetailView(props) {
    const [metadata, setMetadata] = useState({});
    const [imageSpecs, setImageSpecs] = useState({});
    const [imageNotFoundURL, setImageNotFoundURL] = useState("");
    const [cmsAttrsAllowed, setCmsAttrsAllowed] = useState([]);
    const [languagesSupported, setLanguagesSupported] = useState(LANGUAGES_SUPPORTED.slice());

    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedFranchiseId, setSelectedFranchiseId] = useState("");
    const [selectedSeriesId, setSelectedSeriesId] = useState("");
    const [selectedSeasonId, setSelectedSeasonId] = useState("");
    const [selectedTitleId, setSelectedTitleId] = useState("");
    const [selectedShowId, setSelectedShowId] = useState("");
    const [selectedExternalId, setSelectedExternalId] = useState("");
    const [selectedPropEp, setSelectedPropEp] = useState("");
    const [selectedStartTime, setSelectedStartTime] = useState(new Date(INITIAL_START_MS));
    const [selectedEndTime, setSelectedEndTime] = useState(new Date(INITIAL_END_MS));

    const [loadingMetadataOverrides, setLoadingMetadataOverrides] = useState(false);
    const [loadingImageOverrides, setLoadingImageOverrides] = useState(false);
    const [searchClicked, setSearchClicked] = useState(false);

    const [loadingMetadata, setLoadingMetadata] = useState(false);
    const [saving, setSaving] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [allOverrides, setAllOverrides] = useState([]);
    const [allMetadataOverrides, setAllMetadataOverrides] = useState({});
    const [allImageOverrides, setAllImageOverrides] = useState({});

    const [editingImageOverrides, setEditingImageOverrides] = useState(false);
    const [editingMetadataOverrides, setEditingMetadataOverrides] = useState(false);
    const [creatingImageOverrides, setCreatingImageOverrides] = useState(false);
    const [creatingMetadataOverrides, setCreatingMetadataOverrides] = useState(false);

    const [selectedMetadataOverrides, setSelectedMetadataOverrides] = useState({});
    const [selectedImageOverrides, setSelectedImageOverrides] = useState({});

    const [doSearch, setDoSearch] = useState(false);

    const history = useHistory();
    const {search} = useLocation();

    TimeMachineDataProvider.init(process.env.TIME_MACHINE_ADMIN_URL);

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        let doSearch = false;
        if (searchParams.has("category")) {
            setSelectedCategory(searchParams.get("category"));
            doSearch = true;
        }

        if (searchParams.has("showId")) {
            setSelectedShowId(searchParams.get("showId"));
            doSearch = true;
        }

        if (searchParams.has("titleId")) {
            setSelectedTitleId(searchParams.get("titleId"));
            doSearch = true;
        }

        if (searchParams.has("seasonId")) {
            setSelectedSeasonId(searchParams.get("seasonId"));
        }

        if (searchParams.has("seriesId")) {
            setSelectedSeriesId(searchParams.get("seriesId"));
            doSearch = true;
        }

        if (searchParams.has("franchiseId")) {
            setSelectedFranchiseId(searchParams.get("franchiseId"));
            doSearch = true;
        }

        if (searchParams.has("externalId")) {
            setSelectedExternalId(searchParams.get("externalId"));
            doSearch = true;
        }

        if (searchParams.has("propEp")) {
            setSelectedPropEp(searchParams.get("propEp"));
            doSearch = true;
        }

        if (searchParams.has("startTime")) {
            setSelectedStartTime(new Date(parseInt(searchParams.get("startTime")) * 1000));
            doSearch = true;
        }

        if (searchParams.has("endTime")) {
            setSelectedEndTime(new Date(parseInt(searchParams.get("endTime")) * 1000));
            doSearch = true;
        }
        setDoSearch(doSearch);
    }, [JSON.stringify(metadata), search]);

    useEffect(function onUpdateDoSearch() {
        if (doSearch) {
            setDoSearch(false);
            performSearch();
        }
    }, [doSearch]);

    const clearFilters = () => {
        setSelectedCategory("");
        setSelectedFranchiseId("");
        setSelectedSeriesId("");
        setSelectedSeasonId("");
        setSelectedTitleId("");
        setSelectedExternalId("");
        setSelectedPropEp("");
        setSelectedStartTime(new Date(INITIAL_START_MS));
        setSelectedEndTime(new Date(INITIAL_END_MS));
    };


    useEffect(() => {
        if (metadata == null) {
            return;
        }

        let updatedImageSpecs = {};
        let updatedCmsAttrsAllowed = [];
        let updatedImageNotFoundURL = "";
        if (metadata.hasOwnProperty("image_specs")) {
            updatedImageSpecs = Object.assign({}, metadata.image_specs);
        }

        if (metadata.hasOwnProperty("cms_attrs_allowed")) {
            updatedCmsAttrsAllowed = metadata.cms_attrs_allowed.slice();
        }

        if (metadata.hasOwnProperty("image_not_found_url")) {
            updatedImageNotFoundURL = metadata.image_not_found_url;
        }
        setImageSpecs(updatedImageSpecs);
        setCmsAttrsAllowed(updatedCmsAttrsAllowed);
        setImageNotFoundURL(updatedImageNotFoundURL);
    }, [JSON.stringify(metadata)]);

    useEffect(function init() {
        setLoadingMetadata(true);
        TimeMachineDataProvider.getWhitelistedMetadata().then((response) => {
            if (response.hasOwnProperty("default")) {
                setMetadata(response.default);
            } else {
                setMetadata({});
            }
        }).finally(() => {
            setLoadingMetadata(false);
        });


    }, []);

    useEffect(() => {
        const updatedAllOverrides = [];

        for (const override of Object.keys(allMetadataOverrides)) {
            updatedAllOverrides.push(Object.assign({}, allMetadataOverrides[override]));
        }
        console.log("TimeMachineScopeCMSMasterDetailView.loadOverrides: added all metadata overrides: ", updatedAllOverrides);

        for (const override of Object.keys(allImageOverrides)) {
            updatedAllOverrides.push(Object.assign({}, allImageOverrides[override]));
        }
        console.log("TimeMachineScopeCMSMasterDetailView.loadOverrides: added all image overrides: ", updatedAllOverrides);

        setAllOverrides(updatedAllOverrides);
    }, [JSON.stringify(allImageOverrides), JSON.stringify(allMetadataOverrides)]);

    const performSearch = () => {
        setAllMetadataOverrides({});
        setAllImageOverrides({});

        let searchParamString = "";
        searchParamString += selectedCategory ? `category=${selectedCategory}&` : "";
        searchParamString += selectedShowId ? `showId=${selectedShowId}&` : "";
        searchParamString += selectedTitleId ? `titleId=${selectedTitleId}&` : "";
        searchParamString += selectedSeasonId ? `seasonId=${selectedSeasonId}&` : "";
        searchParamString += selectedSeriesId ? `seriesId=${selectedSeriesId}&` : "";
        searchParamString += selectedFranchiseId ? `franchiseId=${selectedFranchiseId}&` : "";
        searchParamString += selectedExternalId ? `externalId=${selectedExternalId}&` : "";
        searchParamString += selectedPropEp ? `propEp=${selectedPropEp}&` : "";
        searchParamString += selectedStartTime !== null ? `startTime=${parseInt((selectedStartTime.getTime() / 1000).toString())}&` : "";
        searchParamString += selectedEndTime !== null ? `endTime=${parseInt((selectedEndTime.getTime() / 1000).toString())}&` : "";
        const urlSearchParams = new URLSearchParams(searchParamString);
        history.replace({pathname: location.pathname, search: urlSearchParams.toString()});

        setSearchClicked(true);
        const metadataOverrides = {};
        const imageOverrides = {};

        if (!selectedCategory || selectedCategory === "Metadata") {
            setLoadingMetadataOverrides(true);
            TimeMachineDataProvider.cmsGetOverrides(selectedShowId, selectedTitleId, selectedSeriesId, selectedFranchiseId, selectedStartTime, selectedEndTime).then((response) => {
                console.log("TimeMachineScopeCMSMasterDetailView.performSearch: ", response);
                if (!response.hasOwnProperty("error")) {
                    for (const override of response) {
                        if (override.hasOwnProperty("franchise_id")) {
                            const formattedOverride = {
                                _id: `metadata-franchise-${override.franchise_id}`,
                                category: "Metadata",
                                lastUpdated: override.lastUpdated,
                                original_payload: override,
                                overrides: override.overrides,
                                scope: "Franchise",
                                scopeId: override.franchise_id,
                                description: override.description ?? ""
                            };
                            metadataOverrides[`metadata-franchise-${override.franchise_id}`] = Object.assign({}, formattedOverride);

                            if (selectedMetadataOverrides && selectedMetadataOverrides.scope === "Franchise" && selectedMetadataOverrides.scopeId === override.franchise_id) {
                                setSelectedMetadataOverrides(Object.assign({}, formattedOverride));
                            }
                        } else if (override.hasOwnProperty("series_id")) {
                            const formattedOverride = {
                                _id: `metadata-series-${override.series_id}`,
                                category: "Metadata",
                                lastUpdated: override.lastUpdated,
                                original_payload: override,
                                overrides: override.overrides,
                                scope: "Series",
                                scopeId: override.series_id,
                                description: override.description ?? ""
                            };
                            metadataOverrides[`metadata-series-${override.series_id}`] = formattedOverride;

                            if (selectedMetadataOverrides && selectedMetadataOverrides.scope === "Series" && selectedMetadataOverrides.scopeId === override.series_id) {
                                setSelectedMetadataOverrides(Object.assign({}, formattedOverride));
                            }
                        } else if (override.hasOwnProperty("season_id")) {
                            const formattedOverride = {
                                _id: `metadata-series-${override.season_id}`,
                                category: "Metadata",
                                lastUpdated: override.lastUpdated,
                                original_payload: override,
                                overrides: override.overrides,
                                scope: "Season",
                                scopeId: override.season_id,
                                description: override.description ?? ""
                            }

                            if (selectedMetadataOverrides && selectedMetadataOverrides.scope === "Season" && selectedMetadataOverrides.scopeId === override.season_id) {
                                setSelectedMetadataOverrides(Object.assign({}, formattedOverride));
                            }
                        } else if (override.hasOwnProperty("prop_ep")) {
                            const formattedOverride = {
                                _id: `metadata-show-${override.prop_ep}`,
                                category: "Metadata",
                                lastUpdated: override.lastUpdated,
                                original_payload: override,
                                overrides: override.overrides,
                                scope: "Title by Prop Ep",
                                scopeId: override.prop_ep,
                                description: override.description ?? ""
                            };
                            metadataOverrides[`metadata-show-${override.prop_ep}`] = formattedOverride;

                            if (selectedMetadataOverrides && selectedMetadataOverrides.scope === "Title by Prop Ep" && selectedMetadataOverrides.scopeId === override.prop_ep) {
                                setSelectedMetadataOverrides(Object.assign({}, formattedOverride));
                            }
                        } else if (override.hasOwnProperty("title_id")) {
                            const formattedOverride = {
                                _id: `metadata-title-${override.title_id}`,
                                category: "Metadata",
                                lastUpdated: override.lastUpdated,
                                original_payload: override,
                                overrides: override.overrides,
                                scope: "Title by Title Id",
                                scopeId: override.title_id,
                                description: override.description ?? ""
                            };
                            metadataOverrides[`metadata-title-${override.title_id}`] = formattedOverride;

                            if (selectedMetadataOverrides && selectedMetadataOverrides.scope === "Title by Title Id" && selectedMetadataOverrides.scopeId === override.title_id) {
                                setSelectedMetadataOverrides(Object.assign({}, formattedOverride));
                            }
                        } else if (override.hasOwnProperty("external_id")) {
                            const formattedOverride = {
                                _id: `metadata-external_id-${override.external_id}`,
                                category: "Metadata",
                                lastUpdated: override.lastUpdated,
                                original_payload: override,
                                overrides: override.overrides,
                                scope: "Show by External Id",
                                scopeId: override.external_id,
                                description: override.description ?? ""
                            };
                            metadataOverrides[`metadata-external_id-${override.external_id}`] = formattedOverride;

                            if (selectedMetadataOverrides && selectedMetadataOverrides.scope === "Show by External Id" && selectedMetadataOverrides.scopeId === override.external_id) {
                                setSelectedMetadataOverrides(Object.assign({}, formattedOverride));
                            }
                        } else if (override.hasOwnProperty("show_id")) {
                            const formattedOverride = {
                                _id: `metadata-show-${override.show_id}`,
                                category: "Metadata",
                                lastUpdated: override.lastUpdated,
                                original_payload: override,
                                overrides: override.overrides,
                                scope: "Show by Show Id",
                                scopeId: override.show_id,
                                description: override.description ?? ""
                            };
                            metadataOverrides[`metadata-show-${override.show_id}`] = formattedOverride;

                            if (selectedMetadataOverrides && selectedMetadataOverrides.scope === "Show by Show Id" && selectedMetadataOverrides.scopeId === override.show_id) {
                                setSelectedMetadataOverrides(Object.assign({}, formattedOverride));
                            }
                        }
                    }
                } else {
                    console.error(response);
                    setErrorMessage("There was an error retrieving shows from Time Machine for the given criteria.");
                }
            }).catch((error) => {
                console.error(error);
                setErrorMessage("There was an error retrieving shows from Time Machine for the given criteria.");
            }).finally(() => {
                setAllMetadataOverrides(metadataOverrides);
                setLoadingMetadataOverrides(false);
            });
        }

        if (!selectedCategory || selectedCategory === "Image") {
            TimeMachineDataProvider.cmsGetImages(selectedFranchiseId, selectedSeriesId, selectedSeasonId, selectedPropEp, selectedTitleId, selectedExternalId, selectedShowId, selectedStartTime, selectedEndTime).then(response => {
                console.log("TimeMachineScopeCMSMasterDetailView.loadOverrides: get images response: ", response);
                for (const override of response) {
                    console.log("TimeMachineScopeCMSMasterDetailView.loadOverrides: formatting override: ", override);
                    let id, scope, scopeId;
                    if (override.hasOwnProperty("franchise_id")) {
                        id = `image-franchise-${override.franchise_id}`;
                        scope = "Franchise";
                        scopeId = override.franchise_id;
                    } else if (override.hasOwnProperty("series_id")) {
                        id = `image-series-${override.series_id}`;
                        scope = "Series";
                        scopeId = override.series_id;
                    } else if (override.hasOwnProperty("season_id")) {
                        id = `image-season-${override.season_id}`;
                        scope = "Season";
                        scopeId = override.season_id;
                    } else if (override.hasOwnProperty("prop_ep")) {
                        id = `image-show-${override.prop_ep}`
                        scope = "Title by Prop Ep";
                        scopeId = override.prop_ep;
                    } else if (override.hasOwnProperty("title_id")) {
                        id = `image-title-${override.title_id}`;
                        scope = "Title by Title Id";
                        scopeId = override.title_id;
                    } else if (override.hasOwnProperty("external_id")) {
                        id = `image-show-${override.external_id}`
                        scope = "Show by External Id";
                        scopeId = override.external_id;
                    } else if (override.hasOwnProperty("show_id")) {
                        id = `image-show-${override.show_id}`
                        scope = "Show by Show Id";
                        scopeId = override.show_id;
                    }

                    imageOverrides[id] = {
                        _id: id,
                        category: "Image",
                        lastUpdated: override.lastUpdated,
                        original_payload: override,
                        description: override.description,
                        scope,
                        scopeId
                    };

                    const updatedOverrides = {i18n: {}};

                    for (const imageId of Object.keys(imageSpecs)) {
                        if (!override.overrides.hasOwnProperty("i18n")) {
                            override.overrides.i18n = {
                                "en-US": {}
                            };
                            for (const key of Object.keys(override.overrides)) {
                                if (key !== "i18n") {
                                    override.overrides.i18n["en-US"][key] = override.overrides[key];
                                    delete override.overrides[key];
                                }
                            }
                        }
                        for (const language of Object.keys(override.overrides.i18n)) {
                            if (!updatedOverrides.i18n.hasOwnProperty(language)) {
                                updatedOverrides.i18n[language] = {};
                            }

                            const imageURL = override.overrides.i18n[language][imageId] ?? "";
                            console.log(`setting URL for override for language ${language} image id ${imageId}: ${imageURL}`);
                            updatedOverrides.i18n[language][imageId] = Object.assign({}, imageSpecs[imageId], {url: imageURL});
                        }
                    }

                    imageOverrides[id].override_source = override.hasOwnProperty("override_source") ? override.override_source : false;
                    imageOverrides[id].overrides = Object.assign({}, updatedOverrides);
                }
            }).catch(error => {
                console.error(error);
            }).finally(() => {
                setAllImageOverrides(imageOverrides);
                setLoadingImageOverrides(false);
            });
        }
    };

    useEffect(() => {
        const category = selectedImageOverrides?.category ?? selectedMetadataOverrides?.category ?? "";
        const scope = selectedImageOverrides?.scope ?? selectedMetadataOverrides?.scope ?? "";
        const scopeId = selectedImageOverrides?.scopeId ?? selectedMetadataOverrides?.scopeId ?? "";

        if (category && scope && scopeId && (editingImageOverrides || editingMetadataOverrides)) {
            for (const override of allOverrides) {
                if (override.scope === scope && override.scopeId === scopeId) {
                    if (override.category === "Image") {
                        setSelectedMetadataOverrides({});
                        setSelectedImageOverrides(Object.assign({}, override));
                    } else if (override.category === "Metadata") {
                        setSelectedImageOverrides({});
                        setSelectedMetadataOverrides(Object.assign({}, override));
                    }
                    if (category === "Image") {
                        setSelectedImageOverrides(Object.assign({}, override));
                        setSelectedMetadataOverrides({});
                    } else if (category === "Metadata") {
                        setSelectedMetadataOverrides(Object.assign({}, override));
                        setSelectedImageOverrides({});
                    }
                }
            }
        }

        console.log("all overrides updated: ", allOverrides, "selected image overrides: ", selectedImageOverrides, "selected metadata overrides: ", selectedMetadataOverrides);
    }, [JSON.stringify(allOverrides), editingImageOverrides, editingMetadataOverrides]);

    const saveImageOverrides = async (overrides, scope, scopeId) => {
        console.log(`TimeMachineScopeCMSMasterDetailView.saveImageOverrides: saving overrides: for scope ${scope} with id ${scopeId}`, overrides);
        setSaving(true);
        TimeMachineDataProvider.cmsAddImageToOverrides(overrides).then(response => {
            console.log("TimeMachineScopeCMSMasterDetailView.saveImageOverrides: response from saving overrides: ", response);
            if (response.hasOwnProperty("error")) {
                setErrorMessage("There was an unexpected error saving images.");
            } else {
                if (selectedCategory === "Metadata") {
                    setSelectedCategory("");
                }

                if (scope === "Franchise" && selectedFranchiseId !== scopeId) {
                    setSelectedFranchiseId(scopeId);
                } else if (scope === "Series" && selectedSeriesId !== scopeId) {
                    setSelectedSeriesId(scopeId);
                } else if (scope === "Season" && selectedSeasonId !== scopeId) {
                    setSelectedSeasonId(scopeId);
                } else if (scope === "Title by Prop Ep" && selectedPropEp !== scopeId) {
                    setSelectedPropEp(scopeId);
                } else if (scope === "Title by Title Id" && selectedTitleId !== scopeId) {
                    setSelectedTitleId(scopeId);
                } else if (scope === "Show by External Id" && selectedExternalId !== scopeId) {
                    setSelectedExternalId(scopeId);
                } else if (scope === "Show by Show Id" && selectedShowId !== scopeId) {
                    setSelectedShowId(scopeId);
                }

                performSearch();
                console.log(`TimeMachineScopeCMSMasterDetailView.saveImageOverrides: setting selected image override: `, overrides);
                const formattedOverride = Object.assign({scope, scopeId}, overrides[0]);

                for (const language of Object.keys(formattedOverride.overrides.i18n)) {
                    for (const imageId of Object.keys(imageSpecs)) {
                        formattedOverride.overrides.i18n[language][imageId] = Object.assign({}, imageSpecs[imageId], {url: formattedOverride.overrides.i18n[language]?.[imageId] ?? ""});
                    }
                }

                setSelectedImageOverrides(formattedOverride);

                setEditingImageOverrides(true);
                setCreatingImageOverrides(false);
                setCreatingMetadataOverrides(false);
                setEditingMetadataOverrides(false);
            }
        }).finally(() => {
            setSaving(false);
        });
    };

    const saveMetadataOverrides = async (scope, scopeId, overrides) => {
        console.log("TimeMachineScopeCMSMasterDetailView.saveMetadataOverrides: saving overrides: ", overrides)
        setErrorMessage("");
        setSuccessMessage("");
        setSaving(true);
        TimeMachineDataProvider.cmsUpdateOverrides([overrides]).then(response => {
            console.log("TimeMachineScopeCMSMasterDetailView.saveMetadataOverrides: response from saving overrides: ", response);
            if (response.hasOwnProperty("error")) {
                setErrorMessage("There was an unexpected error saving images.");
            } else {
                if (selectedCategory === "Image") {
                    setSelectedCategory("");
                }

                if (scope === "Franchise" && selectedFranchiseId !== scopeId) {
                    setSelectedFranchiseId(scopeId);
                } else if (scope === "Series" && selectedSeriesId !== scopeId) {
                    setSelectedSeriesId(scopeId);
                } else if (scope === "Season" && selectedSeasonId !== scopeId) {
                    setSelectedSeasonId(scopeId);
                } else if (scope === "Title by Prop Ep" && selectedPropEp !== scopeId) {
                    setSelectedPropEp(scopeId);
                } else if (scope === "Title by Title Id" && selectedTitleId !== scopeId) {
                    setSelectedTitleId(scopeId);
                } else if (scope === "Show by Show Id" && selectedShowId !== scopeId) {
                    setSelectedShowId(scopeId);
                } else if (scope === "Show by External Id" && selectedExternalId !== scopeId) {
                    setSelectedExternalId(scopeId);
                }

                performSearch();
                for (const override of allOverrides) {
                    console.log(`TimeMachineScopeCMSMasterDetailView.saveMetadataOverrides: checking if override has scope ${scope} and scope id ${scopeId}: `, override);
                    if (override.scope === scope && override.scopeId === scopeId && override.category === "Metadata") {
                        console.log(`TimeMachineScopeCMSMasterDetailView.saveMetadataOverrides: override scope ${scope} and scope id ${scopeId} matches`, override);
                        setSelectedMetadataOverrides(Object.assign({}, override));
                        setSelectedImageOverrides({});
                        setCreatingMetadataOverrides(false);
                        setCreatingImageOverrides(false);
                        setEditingMetadataOverrides(true);
                        setEditingImageOverrides(false);
                    }
                }
            }
        }).finally(() => {
            setSaving(false);
        });
    }

    const addNewLanguage = async language => {
        console.log(`TimeMachineScopeCMSMasterDetailView.addNewLanguage: adding language ${language}`);
        const updatedImageOverrides = Object.assign({}, selectedImageOverrides);
        updatedImageOverrides.overrides.i18n[language] = {};
        for (const imageId of Object.keys(imageSpecs)) {
            updatedImageOverrides.overrides.i18n[language][imageId] = Object.assign({}, imageSpecs[imageId], {url: ""});
        }

        console.log(`TimeMacineScopeCMSMasterDetailView.addNewLanguage: adding language ${language}: `, updatedImageOverrides);
        setSelectedImageOverrides(updatedImageOverrides);
    };

    return (
        editingImageOverrides || creatingImageOverrides ?
            <TimeMachineScopeCMSImageManagerView
                selectedImageOverrides={selectedImageOverrides}
                setSelectedImageOverrides={setSelectedImageOverrides}
                setSuccessMessage={setSuccessMessage}
                successMessage={successMessage}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                imageSpecs={imageSpecs}
                service={props.service}
                module={props.module}
                allPermissions={props.userPermissions}
                user={props.user}
                TimeMachineDataProvider={TimeMachineDataProvider}
                saveImageOverrides={saveImageOverrides}
                editingImageOverrides={editingImageOverrides}
                creatingImageOverrides={creatingImageOverrides}
                setEditingImageOverrides={setEditingImageOverrides}
                setCreatingImageOverrides={setCreatingImageOverrides}
                setSelectedMetadataOverrides={setSelectedMetadataOverrides}
                saving={saving}
                languagesSupported={languagesSupported}
                addNewLanguage={addNewLanguage}
            /> :
        editingMetadataOverrides || creatingMetadataOverrides ?
            <TimeMachineScopeCMSMetadataManagerView
                editingMetadataOverrides={editingMetadataOverrides}
                setEditingMetadataOverrides={setEditingMetadataOverrides}
                setEditingImageOverrides={setEditingImageOverrides}
                setCreatingMetadataOverrides={setCreatingMetadataOverrides}
                setCreatingImageOverrides={setCreatingImageOverrides}
                creatingMetadataOverrides={creatingMetadataOverrides}
                selectedMetadataOverrides={selectedMetadataOverrides}
                setSelectedMetadataOverrides={setSelectedMetadataOverrides}
                cmsAttrsAllowed={cmsAttrsAllowed}
                service={props.service}
                module={props.module}
                allPermissions={props.userPermissions}
                user={props.user}
                TimeMachineDataProvider={TimeMachineDataProvider}
                userCanEdit={true}
                saveMetadataOverrides={saveMetadataOverrides}
                saving={saving}
            /> :
            <TimeMachineScopeCMSSearchView
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedFranchiseId={selectedFranchiseId}
                setSelectedFranchiseId={setSelectedFranchiseId}
                selectedSeriesId={selectedSeriesId}
                setSelectedSeriesId={setSelectedSeriesId}
                selectedSeasonId={selectedSeasonId}
                setSelectedSeasonId={setSelectedSeasonId}
                selectedTitleId={selectedTitleId}
                setSelectedTitleId={setSelectedTitleId}
                selectedShowId={selectedShowId}
                setSelectedShowId={setSelectedShowId}
                selectedExternalId={selectedExternalId}
                setSelectedExternalId={setSelectedExternalId}
                selectedPropEp={selectedPropEp}
                setSelectedPropEp={setSelectedPropEp}
                selectedStartTime={selectedStartTime}
                setSelectedStartTime={setSelectedStartTime}
                selectedEndTime={selectedEndTime}
                setSelectedEndTime={setSelectedEndTime}
                imageSpecs={imageSpecs}
                imageNotFoundURL={imageNotFoundURL}
                performSearch={performSearch}
                clearFilters={clearFilters}
                allOverrides={allOverrides}
                selectedImageOverrides={selectedImageOverrides}
                setSelectedImageOverrides={setSelectedImageOverrides}
                selectedMetadataOverrides={selectedMetadataOverrides}
                setSelectedMetadataOverrides={setSelectedMetadataOverrides}
                editingImageOverrides={editingImageOverrides}
                setEditingImageOverrides={setEditingImageOverrides}
                editingMetadataOverrides={editingMetadataOverrides}
                setEditingMetadataOverrides={setEditingMetadataOverrides}
                searchClicked={searchClicked}
                successMessage={successMessage}
                errorMessage={errorMessage}
                loadingMetadata={loadingMetadata}
                loadingOverrides={loadingMetadataOverrides}
                loadingImages={loadingImageOverrides}
                setCreatingImageOverrides={setCreatingImageOverrides}
                setCreatingMetadataOverrides={setCreatingMetadataOverrides}
            />
    );
};
