import React, {useState, useMemo, useEffect, useRef} from "react";
import {Button, Container, Form, Grid, Icon, Input, Message, Popup, Select, Table, TextArea} from "semantic-ui-react";
import ContingentButton from "../../ContingentButton";
import TimeMachineRundownCMSOverrideEditorTable from "../TimeMachineRundownCMS/TimeMachineRundownCMSOverrideEditorTable";

const SCOPES = ["Franchise", "Series", "Season", "Title by Title Id", "Title by Prop Ep", "Show by External Id", "Show by Show Id"];

export default function TimeMachineScopeCMSMetadataManagerView(props) {
    const [originalMetadataOverrides, setOriginalMetadataOverrides] = useState([]);
    const [currentMetadataOverrides, setCurrentMetadataOverrides] = useState([]);
    const [addOverrideDisabled, setAddOverrideDisabled] = useState(false);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [scope, setScope] = useState("");
    const [scopeId, setScopeId] = useState("");
    const [originalDescription, setOriginalDescription] = useState("");
    const [currentDescription, setCurrentDescription] = useState("");
    const [scopeOptions, setScopeOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    let requestScopeInfo = useRef();

    useEffect(function initialize() {
        const updatedScopeOptions = [];
        for (const scope of SCOPES) {
            updatedScopeOptions.push({key: scope, text: scope, value: scope});
        }
        setScopeOptions(updatedScopeOptions);
    }, []);

    useMemo(function updateSaveDisabled() {
        setSaveDisabled(!unsavedChanges);
    }, [unsavedChanges]);

    useMemo(function onOverrideUpdate() {
        console.log("TimeMachineScopeCMSMetadataManagerView.onOverrideUpdate: overrides: ", props.selectedMetadataOverrides)
        const updatedMetadataOverrides = [];
        let updatedScope = "";
        let updatedScopeId = "";
        let updatedDescription = "";

        if (props.selectedMetadataOverrides) {
            if (props.selectedMetadataOverrides.hasOwnProperty("scope") && props.selectedMetadataOverrides.scope.length > 0) {
                updatedScope = props.selectedMetadataOverrides.scope;
            }

            if (props.selectedMetadataOverrides.hasOwnProperty("scopeId") && props.selectedMetadataOverrides.scopeId.length > 0) {
                updatedScopeId = props.selectedMetadataOverrides.scopeId;
            }

            if (props.selectedMetadataOverrides.hasOwnProperty("description") && props.selectedMetadataOverrides.description.length > 0) {
                updatedDescription = props.selectedMetadataOverrides.description;
            }

            if (props.selectedMetadataOverrides.overrides) {
                for (const key of Object.keys(props.selectedMetadataOverrides.overrides)) {
                    updatedMetadataOverrides.push({key, value: props.selectedMetadataOverrides.overrides[key]});
                }
            }
        }

        setScope(updatedScope);
        setScopeId(updatedScopeId);
        setOriginalDescription(updatedDescription);
        setCurrentDescription(updatedDescription);
        console.log("TimeMachineScopeCMSMetadataManagerView.onOverrideUpdate: from overrides: ", props.selectedMetadataOverrides, "formatted: ", updatedMetadataOverrides);

        setOriginalMetadataOverrides(updatedMetadataOverrides.slice());
        setCurrentMetadataOverrides(updatedMetadataOverrides.slice());

    }, [JSON.stringify(props.selectedMetadataOverrides), props.saving, props.creatingMetadataOverrides, props.editingMetadataOverrides]);

    useMemo(function updateAddOverrideDisabled() {
        let updatedAddOverrideDisabled = false;
        for (const override of currentMetadataOverrides) {
            updatedAddOverrideDisabled ||= override.key === "";
            if (updatedAddOverrideDisabled) {
                break;
            }
        }

        setAddOverrideDisabled(updatedAddOverrideDisabled);
    }, [JSON.stringify(currentMetadataOverrides)]);

    useEffect(function onUpdateScopeOrScopeId() {
        if (props.creatingMetadataOverrides && scope.length > 0 && scopeId.length > 0) {
            if (requestScopeInfo.current) {
                clearTimeout(requestScopeInfo.current);
            }

            setLoading(true);

            requestScopeInfo.current = setTimeout(() => {
                new Promise(resolve => {
                    let debouncedSearchData;
                    if (scope === "Franchise") {
                        props.TimeMachineDataProvider.cmsGetOverrides(null, null, null, null, null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Series") {
                        props.TimeMachineDataProvider.cmsGetOverrides(null, null, null, null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Season") {
                        props.TimeMachineDataProvider.cmsGetOverrides(null, null, null, null, scopeId).then(response => {
                            resolve(response);
                        })
                    } else if (scope === "Title by Prop Ep") {
                        props.TimeMachineDataProvider.cmsGetOverrides(null, null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Title by Title Id") {
                        props.TimeMachineDataProvider.cmsGetOverrides(null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Show by External Id") {
                        props.TimeMachineDataProvider.cmsGetOverrides(null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Show by Show Id") {
                        props.TimeMachineDataProvider.cmsGetOverrides(scopeId).then(response => {
                            resolve(response);
                        });
                    }
                    else {
                        console.error("TimeMachineScopeCMSMetadataManagerView.onUpdateScopeOrScopeId: Unexpected scope: ", scope, "with scope id ", scopeId);
                        throw new Error("unexpected scope");
                    }
                }).then(debouncedSearchData => {
                    console.log("TimeMachineScopeCMSMetadataManagerView.onUpdateScopeOrScopeId: debounced search data: ", debouncedSearchData);


                    if (Array.isArray(debouncedSearchData) && debouncedSearchData.length > 0) {
                        const scopeOverrides = debouncedSearchData[0];  // There should only be one
                        const formattedOverride = {
                            _id: `metadata-franchise-${scopeOverrides.franchise_id}`,
                            category: "Metadata",
                            lastUpdated: scopeOverrides.lastUpdated,
                            original_payload: scopeOverrides,
                            overrides: scopeOverrides.overrides,
                            scope,
                            scopeId
                        }

                        props.setEditingMetadataOverrides(true);
                        props.setCreatingMetadataOverrides(false);
                        props.setSelectedMetadataOverrides(formattedOverride);
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }, 3000);
        }
    }, [props.creatingImageOverrides, scope, scopeId]);

    useMemo(function checkUpdates() {
        if (originalMetadataOverrides.length === 0 && currentMetadataOverrides.length === 0) {
            setUnsavedChanges(false);
            return;
        }

        let updatedUnsavedChanges = currentDescription !== originalDescription;

        if (updatedUnsavedChanges) {
            console.log("TimeMachineScopeCMSMetadataManagerView.checkUpdates: current description: ", currentDescription, "original description: ", originalDescription);
            setUnsavedChanges(updatedUnsavedChanges);
            return;
        }

        const originalOverridesObject = {};
        const currentOverridesObject = {};

        for (const override of originalMetadataOverrides) {
            originalOverridesObject[override.key] = override.value;
        }

        for (const override of currentMetadataOverrides) {
            currentOverridesObject[override.key] = override.value;
        }

        console.log("TimeMachineScopeCMSMetadataManagerView.checkUpdates: original override object: ", originalOverridesObject, "current override object: ", currentOverridesObject);

        for (const [key, value] of Object.entries(originalOverridesObject)) {
            updatedUnsavedChanges ||= value !== currentOverridesObject[key];
            if (updatedUnsavedChanges) {
                setUnsavedChanges(updatedUnsavedChanges);
                return;
            }
        }

        for (const [key, value] of Object.entries(currentOverridesObject)) {
            updatedUnsavedChanges ||= value !== originalOverridesObject[key];
            if (updatedUnsavedChanges) {
                setUnsavedChanges(updatedUnsavedChanges);
                return;
            }
        }
        setUnsavedChanges(updatedUnsavedChanges);
    }, [JSON.stringify(originalMetadataOverrides), JSON.stringify(currentMetadataOverrides), originalDescription, currentDescription]);

    const saveOverrides = () => {
        const metadataOverridesToSave = {};
        for (const override of currentMetadataOverrides) {
            metadataOverridesToSave[override.key] = override.value;
        }

        const overridesToSave = {
            description: currentDescription,
            overrides: metadataOverridesToSave
        };

        if (scope === "Franchise") {
            overridesToSave.franchise_id = scopeId;
        } else if (scope === "Series") {
            overridesToSave.series_id = scopeId;
        } else if (scope === "Season") {
            overridesToSave.season_id = scopeId;
        } else if (scope === "Title") {
            overridesToSave.title_id = scopeId;
        } else if (scope === "Show") {
            overridesToSave.show_id = scopeId;
        } else if (scope === "ExternalId") {
            overridesToSave.external_id = scopeId;
        } else if (scope === "PropEp") {
            overridesToSave.prop_ep = scopeId;
        }

        console.log(`TimeMachineScopeCMSMetadataManagerView.saveOverrides: scope ${scope}, scope id ${scopeId}, overrides to save: `, overridesToSave);

        props.saveMetadataOverrides(scope, scopeId, overridesToSave);
    };

    return (
        <Grid className="masterContainer" divided>
            {
                props.successMessage ?
                    <Message icon success>
                        <Icon name="check" />
                        <Message.Content>{props.successMessage}</Message.Content>
                    </Message> :
                props.errorMessage ?
                    <Message icon error>
                        <Icon name="warning" />
                        <Message.Content>{props.errorMessage}</Message.Content>
                    </Message> : ""
            }
            <Grid.Row>
                <Container fluid>
                    <Button
                        floated="left"
                        onClick={
                            () => {
                                setOriginalMetadataOverrides([]);
                                setCurrentMetadataOverrides([]);
                                props.setEditingImageOverrides(false);
                                props.setEditingMetadataOverrides(false);
                                props.setCreatingImageOverrides(false);
                                props.setCreatingMetadataOverrides(false);
                            }
                        }
                    ><Icon name="arrow left"/>Go Back</Button>
                    <Popup
                        open={unsavedChanges}
                        content="You have made unsaved changes."
                        position="left center"
                        trigger={
                            <ContingentButton
                                floated="right"
                                primary
                                onClick={saveOverrides}
                                disabled={saveDisabled || !props.userCanEdit}
                                allPermissions={props.allPermissions}
                                module={props.module}
                                scope="any"
                                service={props.service}
                                user={props.user}
                                hideIfNotPermitted={true}
                            >
                                <Icon name={props.saving ? "spinner" : "save"} loading={props.saving} />Save&nbsp;Overrides
                            </ContingentButton>
                        }
                    />
                </Container>
            </Grid.Row>
            {
                props.saving ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Saving metadata overrides...</Message.Content>
                    </Message> : ""
            }
            <Grid.Row>
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Scope</Table.HeaderCell>
                            <Table.HeaderCell>Scope ID</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                {
                                    props.editingMetadataOverrides ? scope :
                                    props.creatingMetadataOverrides ?
                                        <Select
                                            options={scopeOptions}
                                            value={scope}
                                            onChange={(event, {value}) => setScope(value)}
                                            placeholder="Scope"
                                        /> : ""

                                }
                            </Table.Cell>
                            <Table.Cell>
                                {
                                    props.editingMetadataOverrides ? scopeId :
                                    props.creatingMetadataOverrides ?
                                        <Input
                                            value={scopeId}
                                            onChange={(event, {value}) => setScopeId(value)}
                                            placeholder="Scope ID"
                                        /> : ""
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell colSpan="2">
                                <Form>
                                    <Form.Group>
                                        <Form.Field
                                            width={16}
                                            control={TextArea}
                                            label="Description"
                                            value={currentDescription}
                                            onChange={(event, {value}) => setCurrentDescription(value)}
                                            fluid
                                        />
                                    </Form.Group>
                                </Form>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Grid.Row>
            {
                loading ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>Checking if {scope} with id {scopeId} exists...</Message.Content>
                    </Message> : ""
            }
            <Grid.Row>
                <Grid.Column width={16}>
                    <TimeMachineRundownCMSOverrideEditorTable
                        overrides={currentMetadataOverrides}
                        setOverrides={setCurrentMetadataOverrides}
                        addOverrideDisabled={addOverrideDisabled}
                        cmsAttrsAllowed={props.cmsAttrsAllowed}
                        userCanEdit={props.userCanEdit}
                        allPermissions={props.allPermissions}
                        module={props.module}
                        instance="any"
                        service={props.service}
                        user={props.user}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};
