import React, {useState, useEffect} from "react";
import {Button, Form, Grid, Icon, Input, Message, Select} from "semantic-ui-react";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ReactTable from "react-table-v6";
import TimeMachineDataProvider from "../../../Services/TimeMachineDataProvider";
import TimeMachineScopeCMSSubComponent from "./TimeMachineScopeCMSSubComponent";

const DATE_DISPLAY_OPTIONS = {year: "numeric", month: "numeric", day: "numeric", hour: "numeric", minute: "numeric", second: "numeric"};
const CATEGORIES = ["Image", "Metadata"];


export default function TimeMachineScopeCMSSearchView(props) {
    const [selectedOverrides, setSelectedOverrides] = useState({});
    const [categoryOptions, setCategoryOptions] = useState([]);

    const [expanded, setExpanded] = useState({});

    TimeMachineDataProvider.init(process.env.TIME_MACHINE_ADMIN_URL);

    useEffect(function init() {
        const updatedCategoryOptions = [];
        for (const category of CATEGORIES) {
            updatedCategoryOptions.push({key: category, text: category, value: category});
        }
        setCategoryOptions(updatedCategoryOptions);
    }, []);

    const createNewImageOverrides = () => {
        const updatedImageOverrides = {
            override_source: false
        };

        const overrides = {};
        for (const imageId of Object.keys(props.imageSpecs)) {
            overrides[imageId] = props.imageSpecs[imageId];
        }
        updatedImageOverrides.overrides = overrides;
        console.log("new image overrides: ", updatedImageOverrides);
        props.setSelectedImageOverrides(updatedImageOverrides);
        props.setCreatingImageOverrides(true);
    }

    const createNewMetadataOverrides = () => {
        props.setSelectedMetadataOverrides({overrides: {}});
        props.setCreatingMetadataOverrides(true);
    }

    useEffect(() => {
        if (Object.keys(props.selectedImageOverrides).length > 0) {
            setSelectedOverrides(Object.assign({}, props.selectedImageOverrides));
        } else if (Object.keys(props.selectedMetadataOverrides).length > 0) {
            setSelectedOverrides(Object.assign({}, props.selectedMetadataOverrides));
        } else {
            setSelectedOverrides({});
        }
    }, [JSON.stringify(props.selectedImageOverrides), JSON.stringify(props.selectedMetadataOverrides)]);

    return (
        <Grid className="masterContainer">
            <Grid.Column width={3}>
                <Form>
                    <Form.Field>
                        <Button fluid color="blue" onClick={
                            () => {
                                props.setSelectedMetadataOverrides({});
                                props.setSelectedImageOverrides({});
                                setExpanded({});
                                props.performSearch();
                            }
                        }>Search</Button>
                    </Form.Field>
                    <Form.Field>
                        <Button fluid color="black" onClick={props.clearFilters}>Clear Filters</Button>
                    </Form.Field>
                        <Form.Group widths="equal">

                            <Form.Field>
                                <Button.Group widths={8}>
                                    <Button fluid color="blue" onClick={createNewImageOverrides} attached="right">Create Image Overrides</Button>
                                    <Button fluid color="blue" onClick={createNewMetadataOverrides} attached="left">Create Metadata Overrides</Button>
                                </Button.Group>
                            </Form.Field>
                        </Form.Group>
                    <Form.Field
                        label="Category"
                        control={Select}
                        value={props.selectedCategory}
                        options={categoryOptions}
                        onChange={(event, {value}) => props.setSelectedCategory(value)}
                        clearable
                    />
                    <Form.Field
                        label="Show ID"
                        control={Input}
                        value={props.selectedShowId}
                        onChange={(event, {value}) => props.setSelectedShowId(value)}
                    />
                    <Form.Field
                        label="External ID"
                        control={Input}
                        value={props.selectedExternalId}
                        onChange={(event, {value}) => props.setSelectedExternalId(value)}
                    />
                    <Form.Field
                        label="Title ID"
                        control={Input}
                        value={props.selectedTitleId}
                        onChange={(event, {value}) => props.setSelectedTitleId(value)}
                    />
                    <Form.Field
                        label="PropEp"
                        control={Input}
                        value={props.selectedPropEp}
                        onChange={(event, {value}) => props.setSelectedPropEp(value)}
                    />
                    <Form.Field
                        label="Season ID"
                        control={Input}
                        value={props.selectedSeasonId}
                        onChange={(event, {value}) => props.setSelectedSeasonId(value)}
                    />
                    <Form.Field
                        label="Series ID"
                        control={Input}
                        value={props.selectedSeriesId}
                        onChange={(event, {value}) => props.setSelectedSeriesId(value)}
                    />
                    <Form.Field
                        label="Franchise ID"
                        control={Input}
                        value={props.selectedFranchiseId}
                        onChange={(event, {value}) => props.setSelectedFranchiseId(value)}
                    />
                    <Form.Field>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <label>Start Time</label>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                value={props.selectedStartTime}
                                onChange={props.setSelectedStartTime}
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                    <Form.Field width={16}>
                        <label>End Time</label>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker
                                inputVariant="outlined"
                                fullWidth
                                value={props.selectedEndTime}
                                onChange={props.setSelectedEndTime}
                            />
                        </MuiPickersUtilsProvider>
                    </Form.Field>
                </Form>
            </Grid.Column>
            <Grid.Column width={13}>
                {
                    props.loadingMetadata ?
                        <Message color="yellow" icon="info">
                            <Message.Content>Loading metadata...</Message.Content>
                        </Message> :
                    !props.searchClicked ?
                        <Message color="yellow" icon="info">
                            <Message.Content>Please enter your search criteria and search to continue.</Message.Content>
                        </Message> :
                    props.loadingOverrides ?
                        <Message color="yellow" icon>
                            <Icon name="spinner" loading />
                            <Message.Content>
                                Loading overrides...
                            </Message.Content>
                        </Message> :
                    props.loadingImages ?
                        <Message color="yellow" icon>
                            <Icon name="spinner" loading />
                            <Message.Content>Loading images...</Message.Content>
                        </Message> :
                        <>
                            {
                                props.errorMessage ?
                                    <Message error icon>
                                        <Icon name="warning" />
                                        <Message.Header>Error</Message.Header>
                                        <Message.Content>{props.errorMessage}</Message.Content>
                                    </Message> : ""
                            }
                            <ReactTable
                                columns={[
                                    { accessor: "_id", show: false },
                                    { Header: "Category", accessor: "category"},
                                    { Header: "Scope", accessor: "scope"},
                                    { Header: "Scope ID", accessor: "scopeId" },
                                    {
                                        Header: "Last Updated",
                                        accessor: "lastUpdated",
                                        Cell: (row) => {
                                            return <span>{new Date(row.original.lastUpdated * 1000).toLocaleString("en-US", DATE_DISPLAY_OPTIONS)}</span>;
                                        }
                                    },
                                    {accessor: "overrides", show: false},
                                    {accessor: "override_source", show: false},
                                    {accessor: "original_payload", show: false}
                                ]}
                                data={props.allOverrides}
                                loading={props.loadingImages || props.loadingOverrides}
                                className="-striped -highlight"
                                filterable
                                defaultPageSize={100}
                                defaultSorted={[
                                    {id: "lastUpdated", desc: true}
                                ]}
                                expanded={expanded}
                                onExpandedChange={
                                    (expanded, index) => {
                                        const updatedExpanded = expanded.hasOwnProperty(index) ? expanded[index] : false;
                                        setExpanded({[index]: updatedExpanded});
                                    }
                                }
                                collapseOnDataChange={false}
                                freezeWhenExpanded={true}
                                SubComponent={
                                    (row) =>
                                        <TimeMachineScopeCMSSubComponent
                                            selectedOverrides={selectedOverrides}
                                            setEditMetadataOverridesClicked={() => props.setEditingMetadataOverrides(true)}
                                            setEditImageOverridesClicked={() => props.setEditingImageOverrides(true)}
                                            loadingOverrides={props.loadingOverrides}
                                            loadingImageOverrides={props.loadingImages}
                                            selectedImageOverrides={props.selectedImageOverrides}
                                            selectedMetadataOverrides={props.selectedMetadataOverrides}
                                            imageSpecs={props.imageSpecs}
                                            imageNotFoundURL={props.imageNotFoundURL}
                                        />
                                }
                                getTrProps={(state, rowInfo, column, instance) => {
                                    return {
                                        onClick() {
                                            console.log("TimeMachineScopeCMSMasterDetailView.getTrProps: setting selected override: ", rowInfo.original);
                                            if (rowInfo.original.category === "Image") {
                                                props.setSelectedImageOverrides(rowInfo.original);
                                            } else if (rowInfo.original.category === "Metadata") {
                                                props.setSelectedMetadataOverrides(rowInfo.original);
                                            }
                                        },
                                        style: {
                                            background: rowInfo && rowInfo.original && selectedOverrides.scope === rowInfo.original.scope && selectedOverrides.scopeId === rowInfo.original.scopeId && selectedOverrides.category === rowInfo.original.category ? "rgba(65, 83, 175, .5)" : "",
                                        }
                                    }
                                }}
                            />
                        </>
                }
            </Grid.Column>
        </Grid>
    );
};
