import React, {useState, useEffect, useRef, useMemo} from "react";
import {Accordion, Button, Checkbox, Confirm, Container, Form, Grid, Icon, Input, Item, Menu, Message, Select, Tab, Table, TextArea} from "semantic-ui-react";
import TimeMachineRundownCMSImageUploadModal from "../TimeMachineRundownCMS/TimeMachineRundownCMSImageUploadModal";
import ContingentButton from "../../ContingentButton";

const SCOPES = ["Franchise", "Series", "Season", "Title by Title Id", "Title by Prop Ep", "Show by External Id", "Show by Show Id"];

export default function TimeMachineScopeCMSImageManagerView(props) {
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isDeletingImage, setIsDeletingImage] = useState(false);
    const [isUploadImageModalOpen, setIsUploadImageModalOpen] = useState(false);
    const [activePanels, setActivePanels] = useState([]);
    const [selectedImage, setSelectedImage] = useState("");

    const [scopeOptions, setScopeOptions] = useState([]);
    const [scope, setScope] = useState("");
    const [scopeId, setScopeId] = useState("");
    const [overrideSource, setOverrideSource] = useState(false);
    const [description, setDescription] = useState("");
    const [imageId, setImageId] = useState("");

    const [loading, setLoading] = useState(false);
    const [addingNewLanguage, setAddingNewLanguage] = useState(false);
    const [newLanguage, setNewLanguage] = useState("");
    const [selectedLanguage, setSelectedLanguage] = useState("en-US");

    let requestScopeInfo = useRef();

    useEffect(() => {
        console.log("TimeMachineScopeCMSImageManagerView: image overrides: ", props.selectedImageOverrides);
    }, [JSON.stringify(props.selectedImageOverrides)]);

    useEffect(() => {
        console.log("TimeMachineScopeCMSImageManagerView: updated props: ", props);
    });

    useEffect(function initialize() {
        const updatedScopeOptions = [];
        for (const scope of SCOPES) {
            updatedScopeOptions.push({key: scope, text: scope, value: scope});
        }
        setScopeOptions(updatedScopeOptions);
    }, []);

    useEffect(function () {
        const updatedActivePanels = [];
        for (let i = 0; i < Object.keys(props.imageSpecs).length; i++) {
            updatedActivePanels.push(true);
        }

        console.log("image specs: ", props.imageSpecs)
        setActivePanels(updatedActivePanels);
    }, [JSON.stringify(props.imageSpecs)]);


    useEffect(() => {
        if (props.editingImageOverrides) {
            setScope(props.selectedImageOverrides.scope || "");
            setScopeId(props.selectedImageOverrides.scopeId || "");
            setDescription(props.selectedImageOverrides.description ?? "");
            setOverrideSource(props.selectedImageOverrides.override_source || false);
        } else {
            setScope("");
            setScopeId("");
            setDescription("");
            setOverrideSource(false);
        }
        console.log("TimeMachineScopeCMSImageManagerView.onUpdateOverrides: selected image overrides: ", props.selectedImageOverrides);
    }, [JSON.stringify(props.selectedImageOverrides), props.creatingImageOverrides, props.editingImageOverrides]);

    useEffect(function onUpdateScopeOrScopeId() {
        if (props.creatingImageOverrides && scope.length > 0 && scopeId.length > 0) {
            if (requestScopeInfo.current) {
                clearTimeout(requestScopeInfo.current);
            }

            setLoading(true);

            requestScopeInfo.current = setTimeout(() => {
                new Promise(resolve => {
                    let debouncedSearchData;
                    if (scope === "Franchise") {
                        props.TimeMachineDataProvider.cmsGetImages(scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Series") {
                        props.TimeMachineDataProvider.cmsGetImages(null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Season") {
                        props.TimeMachineDataProvider.cmsGetImages(null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Title by Prop Ep") {
                        props.TimeMachineDataProvider.cmsGetImages(null, null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Title") {
                        props.TimeMachineDataProvider.cmsGetImages(null, null, null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Show by External Id") {
                        props.TimeMachineDataProvider.cmsGetImages(null, null, null, null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else if (scope === "Show by Show Id") {
                        props.TimeMachineDataProvider.cmsGetImages(null, null, null, null, null, null, scopeId).then(response => {
                            resolve(response);
                        });
                    } else {
                        console.error("TimeMachineScopeCMSImageManagerView.onUpdateScopeOrScopeId: Unexpected scope: ", scope, "with scope id ", scopeId);
                        throw new Error("unexpected scope");
                    }
                }).then(debouncedSearchData => {
                    console.log("TimeMachineScopeCMSImageManagerView.onUpdateScopeOrScopeId: debounced search data: ", debouncedSearchData);


                    if (Array.isArray(debouncedSearchData) && debouncedSearchData.length > 0) {
                        const scopeOverrides = debouncedSearchData[0];  // There should only be one
                        const updatedOverrides = {
                            i18n: {}
                        };
                        const imageOverrides = Object.assign({}, scopeOverrides, {scope, scopeId});
                        console.log("TimeMachineScopeCMSImageManagerView.onUpdateScopeOrScopeId: scope overrides: ", scopeOverrides);
                        if (!scopeOverrides.overrides.hasOwnProperty("i18n")) {
                            scopeOverrides.overrides.i18n = {
                                "en-US": {}
                            };
                            for (const key of Object.keys(scopeOverrides.overrides)) {
                                if (key !== "i18n") {
                                    scopeOverrides.overrides.i18n["en-US"][key] = scopeOverrides.overrides[key];
                                    delete scopeOverrides.overrides[key];
                                }
                            }
                        }
                        console.log("TimeMachineScopeCMSImageManagerView.onUpdateScopeOrScopeId: scope overrides with i18n: ", scopeOverrides);
                        for (const language of Object.keys(scopeOverrides.overrides.i18n)) {
                            if (!updatedOverrides.i18n.hasOwnProperty(language)) {
                                updatedOverrides.i18n[language] = {};
                            }

                            for (const imageId of Object.keys(props.imageSpecs)) {
                                updatedOverrides.i18n[language][imageId] = Object.assign({}, props.imageSpecs[imageId], {url: scopeOverrides?.overrides.i18n[language]?.[imageId] ?? ""});
                            }
                        }


                        imageOverrides.override_source = imageOverrides.override_source ?? false;
                        imageOverrides.overrides = updatedOverrides;
                        props.setEditingImageOverrides(true);
                        props.setCreatingImageOverrides(false);
                        props.setSelectedImageOverrides(imageOverrides);
                    }
                }).finally(() => {
                    setLoading(false);
                });
            }, 3000);
        }
    }, [props.creatingImageOverrides, scope, scopeId]);

    const toggleOverrideSource = () => {
        if (scope !== "" && scopeId !== "") {
            saveClicked(!!!overrideSource);
        }
    };

    const saveClicked = (updatedOverrideSource=overrideSource) => {
        const updatedImages = {i18n: {}};

        if (props.selectedImageOverrides.hasOwnProperty("overrides")) {
            for (const language of Object.keys(props.selectedImageOverrides?.overrides?.i18n ?? {})) {
                if (!updatedImages.i18n.hasOwnProperty(language)) {
                    updatedImages.i18n[language] = {};
                }

                for (const imageId of Object.keys(props.selectedImageOverrides.overrides.i18n[language])) {
                    if (props.selectedImageOverrides.overrides.i18n[language][imageId].url) {
                        updatedImages.i18n[language][imageId] = props.selectedImageOverrides.overrides.i18n[language][imageId].url;
                    }
                }
            }
        }

        const formattedImageOverrides = {
            overrides: updatedImages,
            override_source: updatedOverrideSource,
            description
        };
        if (scope === "Franchise") {
            formattedImageOverrides.franchise_id = scopeId;
        } else if (scope === "Series") {
            formattedImageOverrides.series_id = scopeId;
        } else if (scope === "Season") {
            formattedImageOverrides.season_id = scopeId;
        } else if (scope === "Title by Prop Ep") {
            formattedImageOverrides.prop_ep = scopeId;
        } else if (scope === "Title by Title Id") {
            formattedImageOverrides.title_id = scopeId;
        } else if (scope === "Show by External Id") {
            formattedImageOverrides.external_id = scopeId;
        } else if (scope === "Show by Show Id") {
            formattedImageOverrides.show_id = scopeId;
        }
        console.log("TimeMachineRundownCMSImageManagerView.toggleOverrideSource: saving formatted image overrides: ", formattedImageOverrides, "original image overrides: ", props.selectedImageOverrides);
        props.setErrorMessage("");
        props.setSuccessMessage("");
        return props.saveImageOverrides([formattedImageOverrides], scope, scopeId).then(() => {
            if (props.creatingImageOverrides) {
                props.setSuccessMessage(`Image overrides for the scope ${scope} ID ${scopeId} can now be modified.`);
            } else {
                props.setSuccessMessage(`Original images where the ${scope} ID ${scopeId} are set to${updatedOverrideSource === true ? "" : " not"} be overridden.`);
            }

            return true;
        }).catch(error => {
            console.error(error);
            if (!props.errorMessage) {
                props.setErrorMessage("There was an error saving image overrides.");
            }
            return false;
        });
    };

    const updateImage = (_, _1, imageIdToUpdate, url, languageToUpdate) => {
        if (!imageIdToUpdate) {
            imageIdToUpdate = imageId;
        }

        const urlToDelete = props.selectedImageOverrides.overrides.i18n[languageToUpdate]?.[imageId]?.url ?? "";

        console.log("TimeMachineRundownCMSImageManagerView.updateImage: updating image: ", props.selectedImageOverrides, `for language ${languageToUpdate} with imageId ${imageIdToUpdate} to ${url}`);
        const formattedImageOverrides = {
            overrides: {i18n: {}},
            override_source: overrideSource,
            description
        };
        if (scope === "Franchise") {
            formattedImageOverrides.franchise_id = scopeId;
        } else if (scope === "Series") {
            formattedImageOverrides.series_id = scopeId;
        } else if (scope === "Season") {
            formattedImageOverrides.season_id = scopeId;
        } else if (scope === "Title by Prop Ep") {
            formattedImageOverrides.prop_ep = scopeId;
        } else if (scope === "Title by Title Id") {
            formattedImageOverrides.title_id = scopeId;
        } else if (scope === "Show by External Id") {
            formattedImageOverrides.external_id = scopeId;
        } else if (scope === "Show by Show Id") {
            formattedImageOverrides.show_id = scopeId;
        }
        for (const language of Object.keys(props.selectedImageOverrides.overrides.i18n)) {
            if (!formattedImageOverrides.overrides.i18n.hasOwnProperty(language)) {
                formattedImageOverrides.overrides.i18n[language] = {};
            }

            for (const imageId of Object.keys(props.imageSpecs)) {
                if (imageId === imageIdToUpdate && language === languageToUpdate) {
                    if (url && url.length > 0) {
                        formattedImageOverrides.overrides.i18n[languageToUpdate][imageIdToUpdate] = url;
                    }
                } else if (props.selectedImageOverrides.overrides.i18n[language].hasOwnProperty(imageId) && props.selectedImageOverrides.overrides.i18n[language][imageId].hasOwnProperty("url") &&  props.selectedImageOverrides.overrides.i18n[language][imageId].url.length > 0) {
                    formattedImageOverrides.overrides.i18n[language][imageId] = props.selectedImageOverrides.overrides.i18n[language][imageId].url;
                }
            }
        }


        console.log("TimeMachineRundownCMSImageManagerView.updateImage: saving formatted images: ", formattedImageOverrides);

        props.setErrorMessage("");
        props.setSuccessMessage("");
        return props.saveImageOverrides([formattedImageOverrides], scope, scopeId).then(override => {
            props.setCreatingImageOverrides(false);
            props.setEditingImageOverrides(true);
            props.setSuccessMessage(`The image ${imageIdToUpdate} for ${scope} with ID ${scopeId} has been updated.`);
            setImageId("");
            if (urlToDelete) {
                deleteImage(urlToDelete);
            }

            return true;
        }).catch(error => {
            console.error(error);
            if (!props.errorMessage) {
                props.setErrorMessage("There was an error saving image overrides.");
            }
            return false;
        });
    };

    const handleDeleteImage = () => {
        const urlToDelete = (" " + selectedImage.url).slice(1);
        const {imageId} = selectedImage;

        console.log(`TimeMachineCMSImageEditorItem.handleDeleteImage: removing image with scope ${scope}, scope id ${scopeId}, and image id ${imageId}`)

        if (removeImageFromOverrides(imageId)) {
            console.log(`sending url ${urlToDelete} for deletion`);
            deleteImage(urlToDelete);
        }

        setIsDeleteOpen(false);
    }

    const removeImageFromOverrides = imageId => {
        return updateImage(scope, scopeId, imageId, "", selectedLanguage);
    };

    const deleteImage = url => {
        setIsDeletingImage(true);
        props.TimeMachineDataProvider.cmsDeleteImage(url).then(response => {
            console.log("response from deleting image: ", response);
        }).catch(error => {
            console.error(error);
            props.setErrorMessage(`There was an error deleting the removed image at ${url}.`);
        }).finally(() => {
            setIsDeletingImage(false);
        });
    };

    const addNewLanguage = () => {
        props.addNewLanguage(newLanguage).then(() => {
            setAddingNewLanguage(false);
            setNewLanguage("");
        });
    };

    return (
        <Grid className="masterContainer">
            <TimeMachineRundownCMSImageUploadModal
                scope={scope}
                scopeId={scopeId}
                isModalOpen={isUploadImageModalOpen}
                onOpenModal={() => setIsUploadImageModalOpen(true)}
                onCloseModal={() => setIsUploadImageModalOpen(false)}
                imageOverride={selectedImage}
                TimeMachineDataProvider={props.TimeMachineDataProvider}
                updateImage={updateImage}
                language={selectedLanguage}
            />
            <Confirm
                open={isDeleteOpen}
                onCancel={() => setIsDeleteOpen(false)}
                onConfirm={
                    () => {
                        console.log("TimeMachineScopeCMS.render: deleting image: ", selectedImage);
                        handleDeleteImage();
                        setIsDeleteOpen(false);
                    }
                }
                content="Are you sure you want to delete this image?"
            />
            <Grid.Row>
                <Grid.Column width={16}>
                    <Container fluid>
                        <Button floated="left" onClick={
                            () => {
                                props.setEditingImageOverrides(false);
                                props.setCreatingImageOverrides(false);
                                props.setSelectedImageOverrides({});
                                props.setSelectedMetadataOverrides({});
                                props.setSuccessMessage("");
                                props.setErrorMessage("");
                            }
                        }>
                            <Icon name="arrow left"/>Go Back
                        </Button>
                    </Container>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={16}>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Scope</Table.HeaderCell>
                                <Table.HeaderCell>Scope ID</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>
                                    {
                                        props.editingImageOverrides ?
                                            scope :
                                        props.creatingImageOverrides ?
                                            <Select
                                                options={scopeOptions}
                                                value={scope}
                                                onChange={(event, {value}) => setScope(value)}
                                                placeholder="Scope"
                                            /> : ""
                                    }
                                </Table.Cell>
                                <Table.Cell>
                                    {
                                        props.editingImageOverrides ?
                                            scopeId :
                                        props.creatingImageOverrides ?
                                            <Input value={scopeId} onChange={(event, {value}) => setScopeId(value)} placeholder="Scope ID" /> : ""
                                    }
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell colSpan="2">
                                    <Form>
                                        <Form.Group>
                                            <Form.Field
                                                width={16}
                                                control={TextArea}
                                                label="Description"
                                                value={description}
                                                onChange={(event, {value}) => setDescription(value)}
                                                fluid
                                            />
                                        </Form.Group>
                                        <Button primary disabled={scope === "" || scopeId === ""} onClick={() => saveClicked()}><Icon name="save"/>&nbsp;Save</Button>
                                    </Form>
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {
                        props.editingImageOverrides ?
                            <Table>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell colSpan="2">
                                            <Checkbox
                                                toggle
                                                label="Override Source Images"
                                                checked={overrideSource}
                                                onClick={() => toggleOverrideSource()}
                                                disabled={scope === "" || scopeId === ""}
                                                readOnly={scope === "" || scopeId === ""}
                                            /></Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </Table> : ""
                    }
                </Grid.Column>
            </Grid.Row>
            {
                loading ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>Checking if {scope} with id {scopeId} exists...</Message.Content>
                    </Message> :
                props.creatingImageOverrides === true ?
                    <Message icon color="yellow">
                        <Icon name="info" />
                        <Message.Content>
                            <Message.Header>Creating New Image Override</Message.Header>
                            <p>Please select the scope, enter the scope ID and description, and save in order to upload images for this image override.</p>
                        </Message.Content>
                    </Message> :
                props.saving ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Saving images...</Message.Content>
                    </Message> :
                isDeletingImage ?
                    <Message icon color="blue">
                        <Icon name="spinner" loading />
                        <Message.Content>Deleting old image...</Message.Content>
                    </Message> :
                props.successMessage ?
                    <Message icon success>
                        <Icon name="check" />
                        <Message.Content>{props.successMessage}</Message.Content>
                    </Message> :
                props.errorMessage ?
                    <Message icon error>
                        <Icon name="warning" />
                        <Message.Content>{props.errorMessage}</Message.Content>
                    </Message> : ""
            }
            <Grid.Row>
                {
                    props.editingImageOverrides === true ?
                        <Tab
                            defaultActiveIndex={1}
                            panes={
                                [
                                    {
                                        menuItem: <Menu.Item disabled><Icon name="language"/></Menu.Item>
                                    },
                                    ...Object.keys(props.selectedImageOverrides.overrides.i18n).map(language => {
                                        return {
                                            menuItem: language,
                                            render: () => (
                                                <Tab.Pane>
                                                    <Accordion
                                                        exclusive={false}
                                                        fluid
                                                        styled
                                                        panels={
                                                            Object.keys(props.imageSpecs).map(
                                                                (imageId, i) => {
                                                                    const image = Object.assign({}, props.selectedImageOverrides.overrides.i18n[language][imageId]);
                                                                    console.log(`TimeMachineScopeCMSImageManagerView.updatePanels: for language ${language} and imageId ${imageId} creating image: `, image);
                                                                    const disableRemoveOverrideButton = !props.selectedImageOverrides || !props.selectedImageOverrides.hasOwnProperty("overrides") || !props.selectedImageOverrides.overrides.i18n[language].hasOwnProperty(imageId) || !props.selectedImageOverrides.overrides.i18n[language][imageId].url;

                                                                    return (
                                                                        <Accordion.Panel
                                                                            active={activePanels[i] === true}
                                                                            onTitleClick={
                                                                                () => {
                                                                                    const updatedActivePanels = activePanels.slice();
                                                                                    updatedActivePanels[i] = !updatedActivePanels[i];
                                                                                    setActivePanels(updatedActivePanels);
                                                                                }
                                                                            }
                                                                            title={props.imageSpecs[imageId].name}
                                                                            as={Container}
                                                                            style={{hidden: !activePanels[i]}}
                                                                            content={
                                                                                <Accordion.Content
                                                                                    active={activePanels[i] === true}>
                                                                                    <Item.Group>
                                                                                        <Item>
                                                                                            <Item.Image
                                                                                                size="large"
                                                                                                src={image.url || props.imageNotFoundURL || "https://akamai.warnermediacdn.com/inflow/lower/18c8d64c-9081-4a3d-b15a-a2cdcd6ef19e.jpeg"}
                                                                                                fluid
                                                                                            />
                                                                                            <Item.Content
                                                                                                verticalAlign="top">
                                                                                                <Item.Meta>{image.url ?
                                                                                                    <a href={image.url}
                                                                                                       target="_blank">{image.url}</a> : "No Image Override"}</Item.Meta>
                                                                                                <Item.Description>
                                                                                                    <Item.Description>
                                                                                                        <Table celled basic
                                                                                                               fixed>
                                                                                                            <Table.Body>
                                                                                                                {
                                                                                                                    Object.keys(image).filter(key => !["url", "imageId", "name"].includes(key)).map(
                                                                                                                        key => (
                                                                                                                            <Table.Row>
                                                                                                                                <Table.Cell><b>{`${key.charAt(0).toUpperCase()}${key.slice(1)}`}</b></Table.Cell>
                                                                                                                                <Table.Cell>{image[key]}</Table.Cell>
                                                                                                                            </Table.Row>
                                                                                                                        )
                                                                                                                    )
                                                                                                                }
                                                                                                            </Table.Body>
                                                                                                        </Table>
                                                                                                        <ContingentButton
                                                                                                            allPermissions={props.allPermissions}
                                                                                                            module={props.module}
                                                                                                            scope={props.instance}
                                                                                                            service={props.service}
                                                                                                            user={props.user}
                                                                                                            hideIfNotPermitted={true}
                                                                                                            onClick={
                                                                                                                () => {
                                                                                                                    console.log("TimeMachineScopeCMSImageManagerView.render: updating image with id: ", imageId);
                                                                                                                    setSelectedLanguage(language);
                                                                                                                    setImageId(imageId);
                                                                                                                    setSelectedImage(Object.assign({}, image));
                                                                                                                    setIsUploadImageModalOpen(true);
                                                                                                                }
                                                                                                            }
                                                                                                            disabled={scope === "" || scopeId === ""}
                                                                                                        >Update Image</ContingentButton>
                                                                                                        <ContingentButton
                                                                                                            disabled={disableRemoveOverrideButton || scope === "" || scopeId === ""}
                                                                                                            onClick={
                                                                                                                () => {
                                                                                                                    console.log("TimeMachineScopeCMSImageManagerView.render: updating image with id: ", imageId);
                                                                                                                    setSelectedLanguage(language);
                                                                                                                    setImageId(imageId);
                                                                                                                    setSelectedImage(Object.assign({}, image));
                                                                                                                    setIsDeleteOpen(true);
                                                                                                                }
                                                                                                            }
                                                                                                            floated="right"
                                                                                                            allPermissions={props.allPermissions}
                                                                                                            module={props.module}
                                                                                                            scope="any"
                                                                                                            service={props.service}
                                                                                                            user={props.user}
                                                                                                            hideIfNotPermitted={true}
                                                                                                        >Remove Image</ContingentButton>
                                                                                                    </Item.Description>
                                                                                                </Item.Description>
                                                                                            </Item.Content>
                                                                                        </Item>
                                                                                    </Item.Group>
                                                                                </Accordion.Content>
                                                                            }
                                                                        />
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    />
                                                </Tab.Pane>
                                            )
                                    };
                                    }),
                                    {
                                        menuItem:
                                            <Menu.Item
                                                onClick={() => setAddingNewLanguage(true)}
                                            >
                                                {
                                                    addingNewLanguage ?
                                                        <>
                                                            <Input
                                                                value={newLanguage}
                                                                onChange={(event, {value}) => setNewLanguage(value)}
                                                                placeholder="New Language Code"
                                                            />
                                                            <Button onClick={addNewLanguage}><Icon name="plus" fitted/></Button>
                                                        </>
                                                         :
                                                        <Icon name="plus" fitted />
                                                }
                                            </Menu.Item>
                                    }
                                ]
                            }
                        />
                         : ""
                }
            </Grid.Row>
        </Grid>
    );
};
