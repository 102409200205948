import React, {useMemo} from "react";
import {Button, Container, Divider, Header, Icon, Image, Message, Segment, Tab, Table, Menu} from "semantic-ui-react";


export default function TimeMachineScopeCMSSubComponent(props) {
    const selectedOverrides = useMemo(() => {
        return Object.assign(
            {category: "Unknown", overrides: {}},
            Object.keys(props.selectedImageOverrides).length > 0 ? props.selectedImageOverrides : Object.keys(props.selectedMetadataOverrides).length > 0 ? props.selectedMetadataOverrides : {});
    }, [JSON.stringify(props.selectedImageOverrides), JSON.stringify(props.selectedMetadataOverrides)]);

    const doOverridesExist = useMemo(() => {
        let updatedDoOverridesExist = false;
        console.log("TimeMachineScopeCMSSubComponent.doOverridesExist: selected overrides: ", selectedOverrides);
        for (const language of Object.keys(selectedOverrides.overrides.i18n)) {
            for (const imageId of Object.keys(selectedOverrides.overrides.i18n[language])) {
                console.log(`TimeMachineScopeCMSSubComponent.doOverridesExist: language: ${language}, image id: ${imageId}`, selectedOverrides.overrides.i18n[language][imageId]);

                updatedDoOverridesExist ||= (selectedOverrides.overrides.i18n[language][imageId]?.url ?? "").length > 0;
                if (updatedDoOverridesExist) {
                    break;
                }
            }
            if (updatedDoOverridesExist) {
                break;
            }
        }

       return updatedDoOverridesExist;
    }, [JSON.stringify(selectedOverrides)]);

    return (
        <Segment>
            {
                props.loadingOverrides ?
                    <Message icon color="yellow">
                        <Icon name="spinner" loading />
                        <Message.Content>Loading overrides...</Message.Content>
                    </Message> :
                props.loadingImageOverrides ?
                    <Message icon color="yellow">
                        <Icon color="yellow" name="spinner" loading />
                        <Message.Content>Loading images...</Message.Content>
                    </Message> :
                selectedOverrides.category.toLowerCase() === "image" ?
                    <Container fluid>
                        <Button color="blue" onClick={() => props.setEditImageOverridesClicked(true)} floated="right"><Icon name="images outline"/>Edit Images</Button>
                        <Table basic fixed celled verticalAlign="top" fluid>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Time Machine Payload</Table.HeaderCell>
                                    <Table.HeaderCell>Images</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell verticalAlign="top"><pre>{JSON.stringify(selectedOverrides.original_payload, null, 4)}</pre></Table.Cell>
                                    <Table.Cell verticalAlign="top">
                                        <Table basic celled fixed fluid>
                                            <Table.Body>
                                                {
                                                    doOverridesExist === false ?
                                                        <Message icon>
                                                            <Icon name="info" />
                                                            <Message.Content>
                                                                There are no image overrides to display here.
                                                            </Message.Content>
                                                        </Message> :
                                                        <Tab
                                                            defaultActiveIndex={1}
                                                            panes={[
                                                                {
                                                                    menuItem: <Menu.Item disabled><Icon name="language" size="big" /></Menu.Item>
                                                                },
                                                                ...Object.keys(selectedOverrides.overrides.i18n).map(language => {
                                                                    console.log(`TimeMachineScopeCMSSubComponent.render: filtering for language: ${language}`, selectedOverrides.overrides.i18n[language]);
                                                                    const filteredImageIds = Object.keys(selectedOverrides.overrides.i18n[language]).filter(imageId => {
                                                                        return selectedOverrides.overrides.i18n[language][imageId].url.length > 0;
                                                                    });
                                                                    console.log(`TimeMachineScopeCMSSubComponent.render: filtered for language: ${language}`, filteredImageIds);

                                                                    const filteredSelectedOverrides = [];
                                                                    for (const imageId of filteredImageIds) {
                                                                        filteredSelectedOverrides.push(selectedOverrides.overrides.i18n[language][imageId]);
                                                                    }

                                                                    return {
                                                                        menuItem: language,
                                                                        disabled: filteredSelectedOverrides.length < 1,
                                                                        render: () => {
                                                                            console.log("TimeMachineScopeCMSSubComponent.render: filtered selected overrides: ", filteredSelectedOverrides);

                                                                            return (
                                                                                <Tab.Pane>
                                                                                    <Table>
                                                                                        {
                                                                                            filteredSelectedOverrides.map(override => {
                                                                                                console.log("TimeMachineScopeCMSSubComponent.render: rendering image: ", override);
                                                                                                return (
                                                                                                    <>
                                                                                                        <Table.Body>
                                                                                                            <Table.Row>
                                                                                                                <Table.Cell>
                                                                                                                    <Header textAlign="center">{override.name}</Header>
                                                                                                                </Table.Cell>
                                                                                                            </Table.Row>
                                                                                                            <Table.Row>
                                                                                                                <Table.Cell>
                                                                                                                    <Image size="medium" fluid src={override.url || props.imageNotFoundURL} centered />
                                                                                                                </Table.Cell>
                                                                                                            </Table.Row>
                                                                                                            <Table.Row>
                                                                                                                <Table.Cell>
                                                                                                                    <Divider />
                                                                                                                </Table.Cell>
                                                                                                            </Table.Row>
                                                                                                        </Table.Body>
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </Table>
                                                                                </Tab.Pane>
                                                                            );
                                                                        }
                                                                    }
                                                                }).filter(pane => pane.disabled !== true)
                                                            ]}
                                                        />
                                                }
                                            </Table.Body>
                                        </Table>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Container> :
                selectedOverrides.category.toLowerCase() === "metadata".toLowerCase() ?
                    <Container fluid>
                        <Button disabled color="blue" onClick={() => props.setEditMetadataOverridesClicked(true)}><Icon name="images outline"/>Edit Metadata</Button>
                        <Segment basic><pre>{JSON.stringify(selectedOverrides.original_payload.overrides, null, 4)}</pre></Segment>
                    </Container> : ""
            }
        </Segment>
    );
};
